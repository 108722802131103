import request from "@/plugins/axios";

export const ApiVersion = {
  // 分页
  list(params) {
    return request({
      url: "/homeauto-center-device/device/web/screen-version/list",
      method: "get",
      params
    });
  },
  add(data) {
    return request({
      url: "/homeauto-center-device/device/web/screen-version/save",
      method: "post",
      data
    });
  },
  edit(data) {
    return request({
      url: "/homeauto-center-device/device/web/screen-version/edit",
      method: "post",
      data
    });
  },
  delete(screenVersionId) {
    return request({
      url: `/homeauto-center-device/device/web/screen-version/delete/${screenVersionId}`,
      method: "get"
    });
  },
  templateOptions(projectId) {
    return request({
      url: "/homeauto-center-device/device/web/screen-version/template/selected",
      method: "get",
      params: {
        projectId
      }
    });
  },
  screenVersionUpload(data) {
    return request({
      url: "/homeauto-center-device/device/web/screen-version/upload",
      method: "post",
      data,
      timeout: 5 * 60 * 1000
    });
  },
  uploadScreenConfig(data) {
    return request({
      url: `/homeauto-center-device/device/web/screen-version/screen-config/upload
        `,
      method: "post",
      data
    });
  }
};
